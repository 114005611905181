import type { ItemModel, DataItemModel } from 'o365.modules.DataObject.Types.ts';
import type { DataObjectField } from 'o365.modules.DataObject.Fields.ts';
import type { DataItemOptions } from 'o365.modules.DataObject.Item.ts';

import DataItem from 'o365.modules.DataObject.Item.ts';


export default class DataObjectStorage<T extends ItemModel = ItemModel> {
    //private _getDataObject: () => DataObject<T>;
    private _items: DataItemModel<T>[];
    private _newItemOptionsFactory: () => DataItemOptions<T>;
    private _fields: DataObjectField[];
    private _updatedDebounce: null | number = null;
    private _updated: Date | null = null;
    /** When true will push new records to the end of the storage instead of unshifting them */
    createNewAtTheEnd: boolean = false;

    /** Indexes mapped to DataItems */
    itemsMap: Map<number, DataItemModel<T>> = new Map();
    /** Ids mapped to item indexes */
    itemsIdMap: Map<number, number> = new Map();

    /** Stored items array */
    get data() { return this._items; }
    get items() { return this._items; }
    /** Array of items with changes */
    get changes() { return this.data.filter(x => x.hasChanges); }
    /** Indicates that the storage has an item with changes */
    get hasChanges() { return this.data.some(x => x.hasChanges); }
    /** Date value that gets updated after storage indexes change */
    get updated() { return this._updated; }
    /** Function for getting default item options */
    get newItemOptionsFactory() { return this._newItemOptionsFactory; }
    /** Update item setters and set values with change tracking */
    get updateOrExtendItem() { return this._updateOrExtendItem; }

    constructor(pOptions: {
        newItemOptionsFactory: () => DataItemOptions<T>
        createNewAtTheEnd?: boolean
        fields: DataObjectField[],
    }) {
        this._newItemOptionsFactory = pOptions.newItemOptionsFactory;
        this.createNewAtTheEnd = pOptions.createNewAtTheEnd ?? false;
        this._fields = pOptions.fields;
        this._items = [];
    }

    /**
     * Add item on a specific index. If item already exists, 
     * then will update its values.
     */
    addItem(pItem: T, pIndex: number) {
        if (this._items[pIndex]) {
            this._items[pIndex].extendItem(pItem);
        } else if (pItem instanceof DataItem) {
            pItem.index = pIndex;
            this.data[pIndex] = pItem as DataItemModel<T>;
        } else {
            this._items[pIndex] = (this._createDataItem(pIndex, pItem, this._newItemOptionsFactory())) as DataItemModel<T>;
            this._items[pIndex].initialize();

            this.itemsMap.set(pIndex, this._items[pIndex]);
            if (this._items[pIndex].ID) {
                this.itemsIdMap.set(this.data[pIndex].ID, pIndex);
            }
        }
        this._storageUpdated();
        return this._items[pIndex];
    }

    updateItem(pIndex: number, pValue: Partial<T>, pSkipChangeDetection = false) {
        const item = this.data[pIndex];
        if (item == null) { return undefined; }
        this._updateOrExtendItem(pIndex, pValue);

        if (pSkipChangeDetection) {
            item.reset();
        }

        return this.data[pIndex];
    }

    updateItemsById(pId: number, pValue: Partial<T>, pSkipChangeDetection?: boolean) {
        if (!this.itemsIdMap.has(pId)) {
            return undefined;
        } else {
            return this.updateItem(this.itemsIdMap.get(pId)!, pValue, pSkipChangeDetection);
        }
    }
    updateItemById(pId: number, pValue: Partial<T>, pSkipChangeDetection?: boolean) {
        if (!this.itemsIdMap.has(pId)) {
            return undefined;
        } else {
            return this.updateItem(this.itemsIdMap.get(pId)!, pValue, pSkipChangeDetection);
        }
    }

    updateItemByPrimKey(pPrimKey: string, pValue: Partial<T>, pSkipChangeDetection?: boolean) {
        const item = this.data.find(item => item.PrimKey === pPrimKey);
        if (item == null) {
            return undefined;
        } else {
            return this.updateItem(item.index, pValue, pSkipChangeDetection);
        }
    }

    setItems(pItems: T[], pClear = false, pSkip = 0) {
        const returnData: DataItemModel<T>[] = [];
        if (pClear) {
            this.clearItems();
        } else if (pSkip === 0) {
            pSkip = this._items.length;
        }
        pItems.forEach((item, index) => {
            returnData.push(this.addItem(item, index + pSkip));
        });

        return returnData;
    }

    removeItem(pIndex: number) {
        this._items.splice(pIndex, 1);
        this.reindex();
        this._storageUpdated();
    }

    removeItemsByPrimKeys(pPrimKeys: string[]) {
        const rowsToRemove = this._items.filter(row => pPrimKeys.includes(row.PrimKey));
        for (let i = rowsToRemove.length-1; i >= 0; i--) {
            this._items.splice(rowsToRemove[i].index, 1);
        }
        this.reindex();
        this._storageUpdated();
    }

    /** Clear all items from the storage */
    clearItems() {
        this._items.splice(0, this._items.length);
        this.itemsMap.clear();
        this.itemsIdMap.clear();
        this._storageUpdated();
    }

    /**
     * Set every item's index to their place in the storage array. 
     * Used when moving items around or unshifting them
     */
    reindex() {
        //this.itemsIdMap.clear();
        //this.itemsMap.clear();
        this._items.forEach((item, index) => {
            // if (item.ID) {
            //     this.itemsIdMap.set(+item.ID, index)
            // }
            // this.itemsMap.set(index, item);
            item.index = index
        });
        this._storageUpdated();
    }

    /** Create item model with default field values */
    getEmptyItem(): T {
        const item: Record<string, any> = {};
        this._fields.forEach((field) => {
            if (field.defaultValue !== undefined) {
                item[field.name] = field.defaultValue;
            } else if (typeof field.defaultValueFunction === 'function') {
                item[field.name] = field.defaultValueFunction();
            } else {
                item[field.name] = null;
            }
        });
        return item as T;
    }

    /** Create new item in the storage */
    createNew(pOptions?: T & { cuurrent?: boolean }) {
        const item = this._createNewItem(this.getEmptyItem());
        item.state.isNewRecord = true;
        if (pOptions?.cuurrent) {
            item.current = pOptions.cuurrent;
            delete pOptions.cuurrent;
        }
        if (pOptions && Object.keys(pOptions).length > 0) {
            this._updateOrExtendItem(item.index, pOptions);
        } else {
            item.state.isEmpty = true;
        }
        return this._items[item.index];
    }

    getItem(pIndex: number) {
        return this.data[pIndex];
    }
    getItemByPrimKey(pPrimKey: string) {
        return this.data.find(item => item?.primKey === pPrimKey);
    }
    getItemByKey(pKey: string) {
        return this.data.find(item => item?.key === pKey);
    }
    getItemById(pId: string|number) {
        return this.data.find(item => item?.ID === pId);
    }
    getItemByField<K extends keyof T & string>(pField: K, pValue: T[K]) {
        return this.data.find(item => item[pField] === pValue);
    }
    cancelChanges(pIndex?: number, pKey?: keyof T & string) {
        if (pIndex != null) {
            this.getItem(pIndex)?.cancelChanges(pKey);
        } else {
            this.changes.forEach(item => item.cancelChanges(pKey));
        }
    }

    toJSON() {
        return this.data.map(x => x.item);
    }

    /**
     * No longer necessary to use for reactivity. Items can be updated directly
     * @depricated
     */
    // @ts-ignore
    private updateItemProps(pIndex: number, pValue: T) {
        const item = this.data[pIndex];
        if (item == null) { return; }
        Object.keys(pValue).forEach(key => {
            if (item.hasOwnProperty(key)) {
                (item as any)[key] = pValue[key];
            }
        });
    }

    /** Create DataItem from the provided item model and push/unshift it in the storage */
    private _createNewItem(pItem: T) {
        if (this.createNewAtTheEnd) {
            this._items.push(this._createDataItem(this._items.length, pItem, this._newItemOptionsFactory()) as DataItemModel<T>);
            this._items.at(-1)!.initialize();
            this._storageUpdated();
            return this._items.at(-1)!;
        } else {
            this._items.unshift(this._createDataItem(0, pItem, this._newItemOptionsFactory()) as DataItemModel<T>);
            this._items.at(0)!.initialize();
            this.reindex();
            return this._items.at(0)!;
        }
    }

    /** Update item and initialize setters for new values */
    private _updateOrExtendItem(pIndex: number, pOptions: Partial<T>) {
        Object.keys(pOptions).forEach(key => {
            if (!this._items[pIndex].hasOwnProperty(key)) {
                this._items[pIndex].updateSetter(key);
            }

            (this._items[pIndex] as any)[key] = pOptions[key];
        });
        return this._items[pIndex];
    }

    /** Update the storage.updated value. Used to trigger watchers that are targeting this storage.updated  */
    _storageUpdated() {
        if (this._updatedDebounce) { window.clearTimeout(this._updatedDebounce); }

        this._updatedDebounce = window.setTimeout(() => {
            this._updated = new Date();
            this._updatedDebounce = null;
        }, 50);
    }

    protected _createDataItem(...args: ConstructorParameters<typeof DataItem<T>>) {
        if (this._customDataItemConstructor) {
            return this._customDataItemConstructor(...args);
        } else {
            return new DataItem(...args);
        }
    }

    private _customDataItemConstructor?: (...args: ConstructorParameters<typeof DataItem<T>>) => DataItem<T>
    setDataItemConstructor(pConstructor: ((...args: ConstructorParameters<typeof DataItem<T>>) => DataItem<T>) | null) {
        this._customDataItemConstructor = pConstructor ?? undefined;
    }
}
